// @ts-nocheck
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import propTypes from 'prop-types';
import styles from './modal.module.scss';
import {
  Button,
  ButtonKind,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';
import { ModalType } from './constants';
import { Color } from 'design-system/data';
import classNames from 'classnames';

const ConfirmCancelModal = ({ onClick, onClose, icon, label, description }) => {
  const cancelRef = useRef();

  useEffect(() => {
    cancelRef.current?.focus();
  }, []);

  return (
    <div className={styles['confirm-close-container']}>
      <div className={styles['confirm-close-backdrop']}></div>
      <div className={styles['confirm-close-content']}>
        <div className={styles['container-inner']}>
          <div className={styles.header}>
            {icon ? (
              <span className={styles['icon-container']}>
                <span className={styles['icon-inner']}>
                  <PhosphorIcon
                    iconName={icon}
                    weight={PhosphorIconWeight.Bold}
                    size={24}
                  />
                </span>
              </span>
            ) : (
              <span />
            )}
            <Button
              kind={ButtonKind.Close}
              usePhosphorIcon
              iconWeight={PhosphorIconWeight.Bold}
              iconName="X"
              iconSize={24}
              onlyIcon
              aria-label="Close Modal"
              data-cy="confirm-close-modal-close-button"
              onClick={onClose}
              onKeyDown={(event) => {
                handleKeyboardEvent(
                  event,
                  [KeyboardCode.Enter, KeyboardCode.Space],
                  onClose
                );
              }}
            ></Button>
          </div>
          <div className={styles['confirm-close-content-inner']}>
            <Text
              kind={TextKind.TextMDSemibold}
              element={TextElement.H1}
              color={Color.Neutral900}
            >
              {label || `Are you sure you want to close this modal?`}
            </Text>
            {description && (
              <div className={styles.description}>
                <Text
                  kind={TextKind.TextSM}
                  element={TextElement.P}
                  color={Color.Neutral600}
                >
                  {description}
                </Text>
              </div>
            )}
          </div>
          <div className={styles['footer-container']}>
            <div className={styles.footer}>
              <Button
                kind={ButtonKind.Tertiary}
                onClick={onClose}
                data-cy="confirm-close-modal-cancel-button"
                refProp={cancelRef}
              >
                Cancel
              </Button>
              <Button
                kind={ButtonKind.Primary}
                onClick={onClick}
                data-cy="confirm-close-modal-done-button"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Modal = forwardRef(
  ({ allowDismiss = true, confirmClose = false, ...props }, ref) => {
    const { onClick, id, precloseCallback, children } = props;
    const dialogLabelId = 'dialog-label';
    const dataCyRoot = props.dataCyRoot ? `${props.dataCyRoot}-` : '';
    const type = props.type ? props.type : ModalType.Small;
    const disableOutsideClick = !allowDismiss || confirmClose;
    const [showConfirmClose, setShowConfirmClose] = useState(false);

    useImperativeHandle(ref, () => ({
      // can be utilized in conjunction with precloseCallback to do fancy things, see ChargebeeModal for example.
      simulateHandleOnClick: handleOnClick,
    }));

    const handleOnClick = useCallback(() => {
      if (precloseCallback) {
        precloseCallback();
        return;
      }
      if (confirmClose) {
        setShowConfirmClose(true);
      } else {
        onClick();
      }
    }, [confirmClose, onClick, setShowConfirmClose, precloseCallback]);

    useEffect(() => {
      document.body.style.overflow = props.show ? 'hidden' : 'unset';
      const handleKeydown = (event) => {
        if (props.show && allowDismiss) {
          handleKeyboardEvent(event, [KeyboardCode.Escape], handleOnClick);
        }
      };
      document.addEventListener('keydown', handleKeydown);
      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    }, [props.show, handleOnClick, allowDismiss]);

    return (
      <div id={id} className={styles[props.show ? 'show' : 'hide']}>
        {showConfirmClose && confirmClose && (
          <ConfirmCancelModal
            onClick={() => {
              setShowConfirmClose(false);
              onClick();
            }}
            onClose={() => setShowConfirmClose(false)}
            icon={props.confirmCloseIcon}
            label={props.confirmCloseLabel}
            description={props.confirmCloseDescription}
          />
        )}
        <div
          className={classNames([
            styles.backdrop,
            disableOutsideClick && styles['backdrop-disable'],
          ])}
          onClick={disableOutsideClick ? () => {} : handleOnClick}
        ></div>
        <div
          className={
            children
              ? styles[`container-${type}`]
              : styles['childless-container']
          }
          role="dialog"
          aria-modal="true"
          aria-labelledby={dialogLabelId}
        >
          <div className={styles['container-inner']}>
            <div className={styles.header}>
              <Text
                kind={props.titleSize || TextKind.Display2XSBold}
                element={TextElement.H1}
                id={dialogLabelId}
                data-cy={`${dataCyRoot}modal-title`}
              >
                {props.title}
              </Text>
              {allowDismiss && (
                <Button
                  kind={ButtonKind.Close}
                  usePhosphorIcon
                  iconWeight={PhosphorIconWeight.Bold}
                  iconName="X"
                  onlyIcon
                  aria-label="Close Modal"
                  data-cy={`${dataCyRoot}modal-close-button`}
                  onClick={handleOnClick}
                  onKeyDown={(event) => {
                    handleKeyboardEvent(
                      event,
                      [KeyboardCode.Enter, KeyboardCode.Space],
                      handleOnClick
                    );
                  }}
                ></Button>
              )}
            </div>
            {props.subtitle && (
              <Text
                className={styles.subtitle}
                kind={TextKind.TextMDMedium}
                element={TextElement.P}
                color={Color.Neutral500}
              >
                {props.subtitle}
              </Text>
            )}
            {children && (
              <div
                data-cy="modal-content"
                className={`${styles.content} ${
                  props.showActionButtons
                    ? ''
                    : styles['content-shrink-padding']
                }`}
              >
                {children}
              </div>
            )}
            {props.showActionButtons && (
              <div className={styles['footer-container']}>
                {props.footerSlot ? (
                  props.footerSlot
                ) : (
                  <div className={styles.footer}>
                    {props.allowDismiss && (
                      <Button
                        kind={
                          props.cancelCtaKind
                            ? props.cancelCtaKind
                            : ButtonKind.Tertiary
                        }
                        onClick={props.onCancel}
                        data-cy={`${dataCyRoot}modal-cancel-button`}
                        iconName={props.cancelCtaIcon}
                      >
                        {props.cancelCtaLabel ? props.cancelCtaLabel : 'Cancel'}
                      </Button>
                    )}
                    <Button
                      kind={
                        props.doneCtaKind
                          ? props.doneCtaKind
                          : ButtonKind.Primary
                      }
                      onClick={props.onDone}
                      data-cy={`${dataCyRoot}modal-done-button`}
                      disabled={props.disableDone}
                      iconName={props.doneCtaIcon}
                    >
                      {props.doneCtaLabel ? props.doneCtaLabel : 'Done'}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

Modal.propTypes = {
  id: propTypes.string,
  show: propTypes.bool.isRequired,
  title: propTypes.oneOfType([propTypes.string, propTypes.node]).isRequired,
  onClick: propTypes.func.isRequired,
  children: propTypes.any,
  type: propTypes.oneOf(Object.values(ModalType)),
  showActionButtons: propTypes.bool,
  confirmClose: propTypes.bool,
  confirmCloseIcon: propTypes.string,
  confirmCloseLabel: propTypes.string,
  confirmCloseDescription: propTypes.string,
  precloseCallback: propTypes.func,
  // cancel button
  cancelCtaKind: propTypes.oneOf(Object.values(ButtonKind)),
  cancelCtaLabel: propTypes.string,
  cancelCtaIcon: propTypes.string,
  onCancel: propTypes.func,
  // done button
  doneCtaKind: propTypes.oneOf(Object.values(ButtonKind)),
  doneCtaLabel: propTypes.string,
  doneCtaIcon: propTypes.string,
  onDone: propTypes.func,
  //
  allowDismiss: propTypes.bool,
  dataCyRoot: propTypes.string,
  disableDone: propTypes.bool,
  subtitle: propTypes.string,
  titleSize: propTypes.oneOf(Object.values(TextKind)),
  footerSlot: propTypes.node,
};

export default Modal;
