import { useEffect, useRef, useState } from 'react';
import { Modal, ModalProps, ModalType } from 'design-system/components';
import { ChargebeeType } from 'design-system/types';

import { useApp } from 'context/AppContext';
import styles from './chargebee-modal.module.scss';
import { flushSync } from 'react-dom';

// We ultimately don't own the iframe content, so if chargebee changes..
// .. our implementation can become out of sync.
const ChargebeeModal = () => {
  const modalRef = useRef<{ simulateHandleOnClick: () => void } | null>(null);
  const {
    chargebeeData,
    setChargebeeData,
    refetchRetailerBrand,
    selectedRetailerBrandId,
    retailerBrand,
  } = useApp();

  const initialUnlocked = useRef(Boolean(retailerBrand?.unlocked));
  const [enablePrecloseCallback, setEnablePrecloseCallback] = useState(true);
  const [confirmClose, setConfirmClose] = useState(true);

  // Scroll iframe to top on load without making cors sad.
  useEffect(() => {
    if (chargebeeData?.url) {
      const modal = document.getElementById('chargebee-modal');
      const modalContent = modal?.querySelector('[data-cy="modal-content"]');
      const iframe = document.getElementById('chargebee-iframe');
      iframe?.addEventListener('load', () => {
        if (modalContent) {
          // use hardcoded value b/c measured distance is variable with state.
          modalContent.scrollTop = 158;
        }
      });
    }
  }, [chargebeeData?.url]);

  const handleOnClick = () => {
    modalRef.current?.simulateHandleOnClick();
    setEnablePrecloseCallback(true);
    setConfirmClose(true);
  };

  const precloseCallback = async () => {
    flushSync(() => {
      setEnablePrecloseCallback(false);
    });

    try {
      if (chargebeeData?.type === ChargebeeType.CHECKOUT) {
        await refetchRetailerBrand(selectedRetailerBrandId);

        // a change in unlocked status means the user has paid.
        const hasPaidStatusChanged =
          initialUnlocked.current !== Boolean(retailerBrand?.unlocked);
        if (hasPaidStatusChanged) {
          flushSync(() => {
            setConfirmClose(false);
          });
        }
      }
    } finally {
      handleOnClick();
    }
  };

  return (
    <Modal
      {...({
        show: Boolean(chargebeeData?.url),
        title: chargebeeData?.title,
        onClick: () => setChargebeeData(undefined),
        precloseCallback: enablePrecloseCallback ? precloseCallback : null,
        type: ModalType.ExtraLarge,
        confirmClose: confirmClose,
        confirmCloseLabel: chargebeeData?.exitLabel,
        confirmCloseDescription: chargebeeData?.exitDescription,
        id: 'chargebee-modal',
        ref: modalRef,
      } as ModalProps)}
    >
      <div className={styles.container} id="chargebee-iframe-container">
        <iframe
          id="chargebee-iframe"
          src={chargebeeData?.url}
          title="Chargebee Modal"
          allow="payment"
          loading="lazy"
        />
      </div>
    </Modal>
  );
};

export default ChargebeeModal;
